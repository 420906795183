// Translation
// Migrated
<template>
  <div>
    <component
      :is="allowFullscreen ? 'button' : 'div'"
      class="media-with-text relative overflow-hidden p-0 bg-transparent w-12/12 mb-12 pb-4 md:pb-4"
      @click="allowFullscreen ? showMediaModal() : null"
    >
      <div class="aspect-4/4 xl:aspect-21/9 3xl:aspect-26/9 sm:aspect-16/9 z-[1] relative">
        <LazyMediaPlayer
          v-if="media.mediatype === 'video'"
          class="size-full top-0 overflow-hidden media-with-text-media"
          :url="media.location"
          :type="media.type"
          thumbnail-class="object-cover absolute size-full"
          :custom-thumbnail="media.thumbnail"
          :aspect-ratio="false"
          just-preview
          play-button-class="pb-6"
          :alt="alt + ' video'"
        />
        <LazyResponsiveImage
          v-else
          class="overflow-hidden size-full media-with-text-media"
          :alt="alt"
          :image="media.location"
          :type="media.type"
          cover
        />
      </div>
      <div class="flex flex-col w-full items-center relative media-with-text-content z-[10]">
        <svg
          class="absolute"
          height="200"
          width="800"
        >
          <circle
            cy="400"
            cx="400"
            r="400"
            fill="white"
          />
        </svg>
        <div class="relative text-center text-black">
          <div class="media-with-text-title flex flex-col items-center">
            <slot name="above-title" />
            <h3
              class="whitespace-pre-line mb-4 font-semibold"
              :data-i18n="tTitle"
            >
              {{ toUnixNewlines($t(tTitle)) }}
            </h3>
            <div class="inline-flex flex-row items-center mb-4">
              <hr />
              <span
                class="mx-4"
                :data-i18n="tSubtitle"
              >{{ $t(tSubtitle) }}</span>
              <hr />
            </div>
          </div>
          <slot name="description" />
          <div
            v-if="tEndingText"
            class="h5 font-normal media-with-text-by"
            :data-i18n="tEndingText"
          >
            {{ $t(tEndingText) }}
          </div>
        </div>
      </div>
    </component>
    <LazyMediaModal
      v-if="mediaModalVisible"
      :visible="mediaModalVisible"
      :media-list="[media]"
      size="lg"
      centered
      autoplay
      @on-hide="hideMediaModal"
    />
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    /**
     * @prop {Array<{
     *   location: string
     *   type: 'cloudinary' | 'youtube'
     *   mediatype: 'image' | 'video'
     * }>}
     */
    media: {
      type: Object,
      required: true,
    },

    tTitle: {
      type: String,
      default: '',
    },

    tSubtitle: {
      type: String,
      default: '',
    },

    tEndingText: {
      type: String,
      default: '',
    },

    alt: {
      type: String,
      default: 'Grouptravel banner',
    },

    allowFullscreen: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      mediaModalVisible: false,
    }
  },

  methods: {
    toUnixNewlines,

    showMediaModal () {
      this.mediaModalVisible = true
    },

    hideMediaModal () {
      this.mediaModalVisible = false
    },
  },
})
</script>

<style lang="scss">
$by-text-font-size: 1.25rem;

.media-with-text {
  &-media {
    // height: 400px;
    position: absolute !important;
  }

  &-content {
    margin-top: -60px;
  }

  &-title {
    * {
      font-family: theme('fontFamily.serif');
    }

    h3 {
      line-height: 40px;

      @media (max-width: theme('screens.sm')) {
        font-size: 1.75rem;
      }
    }

    hr {
      width: 50px;
      background: black;
    }
  }

  &-by {
    font-size: $by-text-font-size;
    font-family: theme('fontFamily.serif');
    font-style: italic;
  }

  svg {
    margin-top: -15px;
    transform: scaleY(1.4);

    @media (max-width: theme('screens.sm')) {
      margin-top: -60px;
      transform: scaleY(0.75) scaleX(0.65);
    }
  }
}
</style>
